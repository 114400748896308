import { h, createApp, markRaw } from 'vue';
import { appCreateI18n } from '@/i18n/setup';
import { initSentry } from '@/utils/sentry.js';
import { createHead } from '@vueuse/head';
import { createPinia } from "pinia";
import HideFooter from '@/directives/hideFooter.js';
import Contenteditable from '@/directives/contenteditable.js';
import openNewTab from '@/plugins/openNewTab.js';
import Dragscroll from '@/directives/dragscroll.js';
import { VueReCaptcha } from 'vue-recaptcha-v3';

export function initVueAppOnLoad(selector, component, beforeMount = async () => {}, deriveProps = async () => ({})) {
  const onLoad = async () => {
    const element = document.querySelector(selector);
    if (! element) return;

    const props = await deriveProps(element);

    const app = createApp({
      render: () => h(component, props),
    });
    const pinia = createPinia();

    app.use(createHead());
    app.use(appCreateI18n());
    await beforeMount(app, element);

    const router = app.config.globalProperties.$router;
    const user = JSON.parse(element.dataset.user ?? null);
    initSentry(app, router, user);

    pinia.use(({ store }) => {
      store.router = router ? markRaw(router) : null;
    })

    app.use(pinia);
    app.directive('hide-footer', HideFooter);
    app.directive('contenteditable', Contenteditable);
    app.directive('dragscroll', Dragscroll);
    app.use(openNewTab, {
      className: '.included-in-price-md a, .excluded-in-price-md a'
    });
    app.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY });
    app.mount(selector);
  };

  document.addEventListener('DOMContentLoaded', onLoad);
}
